<template>
  <div id="login2">
    <div class="app flex-row align-items-center">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div class="card-group mb-0">
              <div class="card p-2">
                <div class="card-body">
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <form @submit.prevent="loginUser()">
                    <b-input-group class="input-group mb-3">
                      <b-input-group-prepend>
                        <b-input-group-text>
                          <i class="icon-user"></i>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        type="text"
                        id="userID"
                        class="form-control"
                        placeholder="Username"
                        v-model="loginDetails.userName"
                      />
                    </b-input-group>
                    <b-input-group class="input-group mb-4">
                      <b-input-group-prepend>
                        <b-input-group-text>
                          <i class="icon-lock"></i>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <input
                        type="password"
                        id="password"
                        class="form-control"
                        placeholder="Password"
                        v-model="loginDetails.passWord"
                      >
                    </b-input-group>
                    <div class="row">
                      <div class="col-6">
                        <b-btn type="submit" variant="primary" class="px-4">Login</b-btn>
                      </div>
                      <div class="col-6 text-right">
                        <!-- <button type="button" class="btn btn-link px-0">Forgot password?</button> -->
                      </div>
                    </div>
                  </form>
                  <br>
                  <span style="color:red;">{{errorLogin}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import loginService from "./loginService";
import loginData from "../../script";
export default {
  name: "Login",
  data() {
    return {
      loginDetails: {
        userName: "",
        passWord: ""
      },
      errorLogin: ""
    };
  },
  mounted() {

    console.log(localStorage.getItem("isLogin") + " Login");
    if (localStorage.getItem("isLogin")) {
      console.log("logged in, Login");
      this.$router.push("/home");
    } else {
      console.log("Please login, Login");
    }
  },
  methods: {
    loginUser() {
      var app = this;
      let authUser = {};
      this.$Progress.start();
      loginService
        .login(this.loginDetails)
        .then(res => {
          if (res.data.success) {
            this.$Progress.finish();
            console.log("login success");
            authUser = res.data;
            window.localStorage.setItem("token", authUser.token);
            window.localStorage.setItem("roleID", authUser.role);
            // console.log(authUser)
            loginData
              .getUser()
              .then(res => {
                console.log("Get user success, Login");
                let { language } = res.data.config
                if (language) {
                  this.$i18n.locale = language;
                } else {
                  this.$i18n.locale = 'th'
                  language = 'th'
                }
                window.localStorage.setItem(
                  "language",
                  language
                );
                this.$store.state.nav = this.$t("message.nav")
                window.localStorage.setItem("isLogin", true);
                let userDetails = res.data;
                window.localStorage.setItem("userName", userDetails.userName);
                window.localStorage.setItem("userId", userDetails.userId);
                window.localStorage.setItem("business", userDetails.business);
                window.localStorage.setItem("users", JSON.stringify(userDetails));
                this.$store.state.users = userDetails
                app.$router.push("/dashboard");
              })
              .catch(err => {
                this.$Progress.fail();
                console.log("Cant get user,Login " + err);
                // document.querySelector('.invalidlogin').style.display = 'block'
              });
          } else {
            if (res.data.errorCode === 900) {
              this.$Progress.fail();
              localStorage.setItem("isLogin", false);
              this.errorLogin = "กรุณากรอก username และ password!";
              console.log(res.data);
            } else if (res.data.errorCode === 901) {
              this.$Progress.fail();
              localStorage.setItem("isLogin", false);
              this.errorLogin = "ไม่พบ username นี้ในระบบ!";
              console.log(res.data);
            } else if (res.data.errorCode === 902) {
              this.$Progress.fail();
              localStorage.setItem("isLogin", false);
              this.errorLogin = "username / password ไม่ถูกต้อง";
              console.log(res.data);
            }
          }
        })
        .catch(err => {
          this.$Progress.fail();
          localStorage.setItem("isLogin", false);
          this.errorLogin = "เกิดข้อผิดพลาด";
          console.log("login fail");
          console.log(err);
        });
    }
  }
};
</script>

