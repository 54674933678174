
import {APIENDPOINT, getHeader} from '../../app.config'
import axios from 'axios'
export default {
  login (data) {
    var header = getHeader()
    console.log("API ENDPOINT = " +  APIENDPOINT)
    return new Promise(function (resolve, reject) {
      axios.post(APIENDPOINT + '/v1/user/login', data, {headers: header})
        .then(function (res) {
          resolve(res)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  }
}
